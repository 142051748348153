<template>
    <div id="successPage">
      <i class="icon icon-success"></i>
      <template v-if="type === 1">
        <div class="content">
          <div class="name">変更しました</div>
        </div>
        <div @click="onConfirm" class="submit-btn">
          <span>再度ログイン</span>
        </div>
      </template>
      <template v-else-if="type === 3">
        <div class="content">
          <div class="name">登録成功</div>
        </div>
        <div @click="onConfirm" class="submit-btn">
          <span>再度ログイン</span>
        </div>
      </template>
    </div>
</template>

<script>
export default {
  name: 'Success',
  components: {},
  data () {
    return {
      type: ''
    };
  },
  created () {
  },
  mounted () {
    const { params: { type }, query: { title } } = this.$route;
    this.type = type * 1;
    this.$route.meta.title = title || '';
  },
  watch: {},
  methods: {
    onConfirm () {
      const { type, $router, $utils } = this;
      switch (type) {
        case 1:
        case 3:
          $utils.storage.remove('loginToken');
          $utils.storage.remove('email');
          $router.replace({
            name: 'Login',
            params: {
              type: 1
            }
          });
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
  #successPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 62px 28px;

    .icon-success {
      width: 200px;
      height: 200px;
      margin-bottom: 76px;
      background-image: url("~@/assets/images/icon_success_01.png");
    }

    .content {
      margin-bottom: 136px;

      .name {
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        color: #fff;
      }
    }
  }
</style>